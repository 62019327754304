<template>
  <div>
    <button v-if="currentUser" @click="edit = !edit">Edit Page</button>
    <div v-if="edit" class="modal">
      <label><span class="label">ID:</span> <input disabled type="text" v-model="editpage.id"></label>
      <label><span class="label">Title:</span> <input type="text" v-model="editpage.title"></label>
      <label><span class="label">Body:</span> <textarea v-model="editpage.body"></textarea></label>
      <label><span class="label">Tag:</span> <input type="text" v-model="editpage.tag"></label>

      <button @click="save">Save</button>
      <button @click="cancel">Cancel</button>
    </div>
  </div>


</template>

<script>
/* TODO adding a new page (with a new ID) or deleting a page is untested */

import {
	mapState
} from 'vuex'

export default {
	name: 'editpage',

	props: ['id'],
	data() {
		return {
			edit: false,
		}
	},

	beforeMount() {
		this.init();
	},
	computed: {
		...mapState('page', {
			'pages': 'page'
		}),
		...mapState('user', {
			'currentUser': 'currentUser'
		}),
		page() {
			return this.pages[this.id] || {};
		},
		editpage() {
			// editable copy of the page.  Setters aren't working when I v-model onto child keys of editpage, oh well
			// there's a mapFields npm plugin that generates all the setters and getters for you though, maybe should switch to that
			return {
				id: this.id,
				title: this.page.title,
				body: this.page.body,
				tag: this.page.tag
			}
		}
	},
	methods: {
		init() {
			this.$store.dispatch('page/get', this.id)
		},
		save() {
			this.$store.dispatch('page/edit', this.editpage);
			this.edit = false;
		},
		cancel() {
			this.editpage.id = this.id;
			this.editpage.title = this.page.title;
			this.editpage.body = this.page.body;
			this.editpage.tag = this.page.tag;
			this.edit = false;
		}
	}
}
</script>

<style>
</style>