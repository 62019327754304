<template>
  <div>
    <loading v-if="!page"></loading>
    <div v-else>
      <edit-page v-if="currentUser" :id="page.id"></edit-page>
      <edit-pool v-if="currentUser"></edit-pool>
      <h1 v-html="page.title"></h1>
      <compiled-content :input="page.body"></compiled-content>
    </div>
  </div>
</template>

<script>
import {
	mapState
} from 'vuex'
import CompiledContent from '@/components/CompiledContent';
import EditPage from '@/components/EditPage';
import EditPool from '@/components/EditPool';
import Loading from '@/components/Loading'

export default {
	name: 'page',
	components: {
		CompiledContent,
		EditPage,
		EditPool,
		Loading
	},
	props: ['id', 'tag'],
	data() {
		return {
			// id: this.$route.name,
			isRenderReady: 0 // HACK.  Keep a counter of the store events we're waiting for.  Necessary here because this component doesn't directly observe the pool data it might be loading
		}
	},
	beforeMount() {
		this.init();
	},
	computed: {
		...mapState('page', {
			'pages': 'page'
		}),
		...mapState('user', {
			'currentUser': 'currentUser'
		}),
		page() {
			let p = this.pages[this.id] || false;
			if (p.title) {
				document.title = p.title
			}
			return p;
		}
	},
	methods: {
		init() {
			if (this.tag) {
				this.isRenderReady = -1; // HACK allow an extra event
				this.$store.dispatch('pool/getByTag', this.tag)
				document.addEventListener('pool-getByTag-' + this.tag, this.renderReady, {
					once: true
				});
			}
			if (this.$route.path === '/') {
				this.$store.dispatch('pool/getHomepage');
			}

			this.$store.dispatch('page/get', this.id)
			document.addEventListener('page-get-' + this.id, this.renderReady, {
				once: true
			});
		},
		renderReady() {
			// if the page has tags, this will eventually get called twice (and isRenderReady will start at -1, see init() above)
			// This hack won't work anymore once I start reading the tag from mysql instead of the router.
			// (Instead could use the page() computed fn to fire prerender-trigger if there's no tag, or to wait for getByTag otherwise)
			// (gosh I should've done that in the first place)
			this.isRenderReady += 1;
			if (this.isRenderReady == 1) {
				this.$nextTick(() => {
					document.dispatchEvent(new Event('prerender-trigger'))
				})
			}
		}
	}
}
</script>
